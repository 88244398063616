import React from 'react';
import MainLayout from '../../layouts/mainLayout';
import { OptimizedImage } from '../../components/common/OptimizedImage';

export default () => (
  <MainLayout withTitle>
    <div className="faq-wrapper">
      <div className="faq-content-wrapper">
        <div className="hdr text-left">
          <h2 className="mgn-bot-30 mgn-bot-20">Pause and Resume Orders</h2>
          <p className="qnA-text">
            You can easily pause your orders and then resume them when you are
            ready. Please note that when you resume your order this will restart
            your frequency your order will not be shipped out on this date. If
            you need a box sent out sooner this is best done via a ship today
            order. This is done by clicking the Ship Today button. It looks like
            this:
          </p>

          <h3 className="mgn-top-20 mgn-bot-5">Pause orders:</h3>
          <p className="qnA-text">
            1. Log in to your account and view your Dashboard <br />
            2. Click on "Manage Orders" <br />
            3. Pause your orders
          </p>
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="login.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="manage_order.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="pause_subs.png" />
        </div>
        <h3 className="mgn-top-20 mgn-bot-5">Resume orders:</h3>
        <p className="qnA-text ">
          1. Log in to your account and view your Dashboard <br />
          2. Click on "Resume Orders"
        </p>
        <div className="mgn-top-20 mgn-bot-50">
          <OptimizedImage fileName="login.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="resume_subs.png" />
        </div>
        <p className="qnA-text">
          If you didn't find the answer you were looking for please contact us,
          we would love to help! <br />
          Email: info@feedmyfurbaby.co.nz <br />
          Phone: 09 88 MYPET (09 886 9738) <br />
          Facebook: @myfurbabynz <br />
          Or contact us using our webchat.
        </p>
      </div>
    </div>
  </MainLayout>
);
